import { Link } from "@remix-run/react";
import { useTranslation } from "react-i18next";
import { useAccountSelectorContext } from "~/components/context/accountContext";
import { FooterBlockDto } from "~/modules/pageBlocks/components/blocks/marketing/footer/FooterBlockUtils";
import SocialsVariantSimple from "../../shared/socials/SocialsVariantSimple";

export default function FooterVariantSimple({ item }: { item: FooterBlockDto }) {
  const openAccount = useAccountSelectorContext((state) => state.openAccount);

  const handleClick = (type, name) => {
    if (type === "openLogin") {
      openAccount("click", `footer_${name}`);
    }
  };
  const { t } = useTranslation();
  return (
    <footer>
      <div className="mx-auto max-w-7xl overflow-hidden px-4 py-12 text-center sm:px-6 lg:px-8">
        {item.sections.map((section) => {
          return (
            <nav key={section.name} className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
              {section.items.map((link, index) => {
                if (link.onClick) {
                  return (
                    <div key={link.name} className="px-5 py-2" onClick={() => handleClick(link.onClick, link.name)}>
                      <div className="cursor-pointer text-base text-gray-500 hover:text-gray-900 dark:hover:text-white">{link.name}</div>
                    </div>
                  );
                }
                return (
                  <div key={link.href} className="px-5 py-2">
                    <Link to={link.href} title={link.name} className="cursor-pointer text-base text-gray-500 hover:text-gray-900 dark:hover:text-white">
                      {link.name}
                    </Link>
                  </div>
                );
              })}
            </nav>
          );
        })}
        <div className="mt-4 flex justify-center space-x-6">
          <SocialsVariantSimple item={item.socials} />
        </div>
        <div className="mt-4 flex justify-center space-x-6 text-sm text-gray-500 hover:text-gray-900 dark:hover:text-white">
          PO Box 206 New Hill, NC 27562
        </div>
      </div>
    </footer>
  );
}
