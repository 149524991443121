import { SocialsBlockDto } from "~/modules/pageBlocks/components/blocks/shared/socials/SocialsBlockDto";
import { defaultSocials } from "~/modules/pageBlocks/utils/defaultSocials";

export type FooterBlockDto = {
  style: FooterBlockStyle;
  text?: string;
  sections: FooterSectionDto[];
  socials?: SocialsBlockDto;
  withThemeSwitcher?: boolean;
};

export interface FooterSectionDto {
  name: string;
  items: { name: string; href?: string; onClick?: string; target?: "_blank" }[];
}

export const FooterBlockStyles = [
  { value: "simple", name: "Simple" },
  { value: "columns", name: "Columns" },
] as const;
export type FooterBlockStyle = (typeof FooterBlockStyles)[number]["value"];

export const defaultFooterBlock: FooterBlockDto = {
  style: "columns",
  text: "Footer text",
  withThemeSwitcher: true,
  sections: [
    {
      name: "Application",
      items: [
        { name: "Pricing", onClick: "openLogin" },
        { name: "Sign in", onClick: "openLogin" },
        { name: "Sign up", onClick: "openLogin" },
        // { name: "Blog", href: "/blog" },
      ],
    },
    {
      name: "Product",
      items: [
        { name: "Contact", href: "/contact" },
        { name: "Newsletter", href: "/newsletter" },
        { name: "Terms and Conditions", href: "/policy/categories/terms-of-service" },
        { name: "Privacy Policy", href: "/policy/categories/privacy-policy" },
        { name: "Affiliates", href: "/affiliates" },
        { name: "Content Creators", href: "/content-creators" },
      ],
    },
  ],
  socials: defaultSocials,
};
